.ProfessionalsTop {
  background-size: cover;
  background-position: center;
  height: 35vh;
  background: #0d6763;
  /* url("../../images/CommonImages/CommonBg.png") 0 0/100% 100% no-repeat; */
  background-size: cover;
}
.podCard {
  text-align: center;
  --background: #fff;
  width: 80%;
}
.podCard img {
  padding: 20px;
}
.podCard ion-card-header {
  border-top: 2px solid gray;
}

.table {
  display: table;
  height: 100%;
  width: 100%;
}
.table-cell {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.headingMain {
  margin-top: 0 !important;
  color: #ffffff;
  font-size: 60px;
  text-align: center;
}
.ProfessionalsSection2 {
  display: flex;
}
.ProfessionalsSection3 {
  display: flex;
}
.heading {
  color: #314a8e;
  text-align: left;
  font-size: 40px;
}
.TopPara {
  font-size: 24px;
  color: #756969;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

ol.gradient-list > li,
ol.gradient-list > li::before {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}
ol.gradient-list > li:hover,
ol.gradient-list > li:hover {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem #ffff0091;
}
/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}
main {
  display: block;
  margin: 0 auto;
  /* max-width: 40rem; */
  padding: 0.4rem 1rem;
}
@media (max-width: 991px) {
  .ProfessionalsSection2 {
    display: block;
  }
  .TopPara {
    font-size: 20px;
  }
  .gradient-list li {
    font-size: 16px;
  }
}

@keyframes podcast {
  0% {
    color: #296863;
    background-color: #e9f4f3;
  }
  100% {
    color: #e9f4f3;
    background-color: #296863;
  }
}
