.InFoot {
  font-size: 15px;
  background-color: #0d6763;
}
.footIn {
  padding: 25px 0 40px 0;
  display: flex;
}
.footTabs ul li a:hover {
  color: yellow;
}
.title {
  color: #ffffff;
}
ul {
  padding: 0;
  list-style-type: none;
}
ul li a {
  padding-bottom: 8px;
  display: inline-block;
  font-size: 16px;
  color: #fff;
}
.footIn h4 {
  font-weight: 600;
  font-size: 25px;
}
.col {
  float: left;
}
.logoo {
  max-width: 60px;
}
.scroll {
  min-height: 100%;
}
.toolbar-container {
  position: static;
  background: #007bff;
}
#footer {
  position: static !important;
  bottom: 0px;
  color: #fff;
  width: 100%;
  justify-content: center;
}
a {
  color: inherit;
}
.copyRight {
  float: none;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.copyRight p {
  margin: 0;
  padding: 8px 5px;
  background: #0d6763;
  font-size: 13px;
}

.copyRight a {
  color: #fff;
}

.footTabs ul li a:hover {
  color: yellow;
}

@media (max-width: 767px) {
  .footIn .col {
    width: 100%;
    float: none;
  }
  .footIn {
    padding: 15px 0 0px 0;
    display: block;
  }
  .hidden-sm {
    display: none;
  }
  /* .socialmedia{
    display: none;
  } */
}
/* @media (min-width:768px){ */
.mediaIcon {
  display: flex;
  margin: 0 25px 25px 0;
  padding: 6px;
  width: 2.563rem;
  height: 2.563rem;
  text-align: center;
  border-radius: 100%;
  font-size: 1.375rem;
  line-height: 1.6;
}
.socialmedia {
  margin-left: 50px;
  display: flex;
}
.socialmedia a:hover {
  color: yellow;
}
/* } */
