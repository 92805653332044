.newNewsFeedClass {
	display: flex;
	padding: 0px;
	justify-content: center;
}

.newNewsFeed {
	margin: 20px 35px !important;
	width: 60%;
}

.newNewsFeedFollowing {
	max-width: 100% !important;
	padding: 15px 0;
}

.newNewsFeedFollowingWidth {
	width: 100%;
	padding: 10px 0 !important;
}

.newNewsFeed .share-update-card {
	margin: 10px 0;
}

@media screen and (max-width: 768px) {
	.newNewsFeed {
		margin: 60px 10px 0 10px !important;
		width: 60%;
	}
	.newNewsFeedClass {
		display: flex;
		padding: 0px;
		justify-content: center;
	}
	.newNewsFeedFollowing {
		margin-top: 40px;
	}
}

@media screen and (max-width: 460px) {
	.newNewsFeedClass {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.newNewsFeed {
		margin: 50px 0 0 0 !important;
		width: 100%;
		padding: 10px;
	}
	.newNewsFeedFollowing {
		display: none;
	}
}
