.HomeTop {
  background-size: cover;
  background-position: center;
  height: 90vh;
  background: #0d6763;
  /* url("../../images/HomeImages/HomeBG.jpg") 0 0/100% 100% no-repeat; */
  background-size: cover;
}
.heading {
  font-weight: 600;
}
.table {
  display: table;
  height: 100%;
  width: 100%;
}
ion-content {
  --position: static !important;
}
.col {
  float: left;
}
.abtP {
  padding: 15px 110px 15px 0;
}
.abtImg {
  max-width: 90%;
  margin: 0 auto;
}
.abtlogo {
  background: #0d6763;
  padding: 3px;
  border-radius: 50%;
  max-width: 80px;
}
.theme {
  background: #0d6763;
  border: 1px solid #0d6763;
  color: #fff;
  padding: 10px 25px;
  border-radius: 20px;
}
.table-cell {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.logo {
  max-width: 200px;
}
.headingMain {
  margin-top: 0 !important;
  color: #ffffff;
  font-size: 50px;
  text-align: center;
}
.HomeSection2 {
  display: flex;
}
.HomeSection3 {
  display: flex;
}
.heading {
  color: #0d6763 !important;
  text-align: left;
  font-size: 40px;
}

/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}
main {
  display: block;
  margin: 0 auto;
  /* max-width: 40rem; */
  padding: 0.4rem 1rem;
}

@media (max-width: 991px) {
  .HomeSection3 {
    display: block;
    overflow: hidden;
    clear: both;
  }
  .heading{
    font-size: 25px !important;
  }
}

@media (max-width: 767px) {
  .col {
    float: none;
  }
  /* .headingMain{ */

  /* } */
  .headingMain {
    font-size: 35px !important;
    padding: 0px 5px;
  }
  .logo {
    max-width: 80px !important;
  }
  .HomeTop {
    height: 58vh !important;
  }
  .abtP {
    padding: 0px 0px 8px 0;
  }
  ion-header {
    padding-top: 0px;
  }
  .heading {
    font-size: 28px;
  }
  .abtlogo {
    max-width: 60x;
  }
}
