/* .top-header-app ion-content {
    --background: #f5f7ff;
  }
  
  a {
    cursor: pointer;
  }
   */
.top-header-app ion-buttons {
	color: #0d6763;
	font-size: 25px;
}
.kalyug {
	width: 60%;
}
.right {
	float: right;
	width: 39%;
	text-align: right;
	display: flex;
}
.searchBar {
	width: 400px;
}
.rightIcons .childRight {
	margin-left: 20px;
	color: #fff;
	font-size: 30px;
	display: inline-block;
	vertical-align: middle;
}
.barIcon,
.logo-blue,
.hdr ion-searchbar {
	display: inline-block;
}
.rightIcons .pic {
	margin: 0;
}
.hdr .logo-blue {
	margin: 0px 10px;
}
.barIcon {
	color: #fff;
	font-size: 36px;
}
.barIcon:hover {
	cursor: pointer;
}
.profileImg {
	border-radius: 50%;
	overflow: hidden;
	display: inline-block;
	margin-left: 24px;
	height: 35px;
	width: 35px;
}
.hdr ion-img,
.hdr ion-icon {
	vertical-align: middle;
}
.hdr {
	padding: 0 15px;
}
.searchbar-input.sc-ion-searchbar-md {
	line-height: 25px;
}
ion-header,
ion-header ion-toolbar:first-of-type {
	background: #0d6763;
	--background: #0d6763;
}
.main-header-logo ion-img {
	width: 100%;
	max-width: 150px;
}

.main-header-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
}
.main-header-right .main-header-botification .notilist {
	margin: 0px;
	padding: 0px;
	display: flex;
	align-items: center;
}
.main-header-right .main-header-botification .notilist .notilist-item {
	list-style: none;
	margin: 0px 5px;
	--min-height: auto;
	--padding-start: 0;
	--padding-end: 0;
	--inner-padding-end: 0;
	--inner-padding-start: 0;
	--inner-box-shadow: none;
}
.main-header-right .main-header-botification .notilist .notilist-item a {
	border: 1px solid#0D6763;
	border-radius: 100%;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	a
	ion-icon {
	color: #0d6763;
}
.main-header-right .main-header-botification .notilist .notilist-item a span {
	padding: 5px;
	background-color: #f00;
	position: absolute;
	top: -5px;
	z-index: 1;
	border-radius: 100%;
	right: -7px;
	width: 20px;
	height: 20px;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.main-header-right .main-header-botification .notilist .notilist-item a:hover {
	background-color: #0d6763;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	a:hover
	ion-icon {
	fill: #fff;
	color: #fff;
}
/* .main-header-right .main-header-botification .notilist .notilist-item .notifications-drop:hover .notifications-dropbox {
      display: block;
  } */

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox {
	position: absolute;
	top: 40px;
	right: 215px;
	width: 100%;
	max-width: 500px;
	z-index: 999;
	padding: 0px 10px;
	display: none;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox.drop-show {
	display: block;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner {
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 8px;
	background-color: #fff;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-top {
	padding: 10px 15px;
	border-bottom: 1px solid #e6e6e6;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-top
	h2 {
	font-size: 18px;
	color: #0d6763;
	font-weight: 500;
	margin-bottom: 0px;
	margin-top: 0px;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid {
	max-height: 310px;
	overflow: auto;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list {
	padding: 10px 15px;
	border-bottom: 1px solid #e6e6e6;
	margin: 0px;
	list-style: none;
}

.nonotificationstext {
	padding: 10px;
}

.nonotificationstext p {
	color: #343b5d;
	margin: 0px;
	padding: 0px;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list.unread {
	background-color: #f5f7ff;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a {
	width: 100%;
	border: none;
	height: auto;
	background-color: transparent;
	text-decoration: none;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a
	.notifications-detail {
	width: 100%;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a
	.notifications-detail
	.notifications-detail-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 3px;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a
	.notifications-detail
	.notifications-detail-heading
	h5 {
	font-size: 14px;
	font-weight: 500;
	color: #0d6763;
	margin-bottom: 0px;
	margin-right: 10px;
	margin-top: 0px;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a
	.notifications-detail
	.notifications-detail-heading
	p {
	font-size: 12px;
	font-weight: 400;
	color: #0d6763;
	margin-bottom: 0px;
	margin-top: 0px;
}
.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a
	.notifications-detail
	.notifications-detail-pera
	p {
	font-size: 12px;
	font-weight: 400;
	color: #0d6763;
	margin-bottom: 0px;
	margin-top: 0px;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-btn {
	text-align: center;
	padding: 5px 0px;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-btn
	ion-button {
	--background: #0d6763;
	color: #fff;
	text-decoration: none;
	font-size: 14px;
	display: inline-block;
	--border-radius: 20px;
	text-transform: capitalize;
	--padding-start: 15px;
	--padding-end: 15px;
}

.main-header-right .main-header-user .dropdown .dropdown-toggle {
	background-color: transparent;
	border: none;
	outline: none;
	box-shadow: none;
	display: flex;
	align-items: center;
	color: #0d6763;
	cursor: pointer;
}
.main-header-right .main-header-user .dropdown .dropdown-toggle .drop-userimg {
	margin-right: 5px;
	width: 30px;
	height: 30px;
	overflow: hidden;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid#0D6763;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-toggle
	.drop-userimg
	img {
	width: 100%;
	max-width: 30px;
}
.main-header-right .main-header-user .dropdown .dropdown-toggle h4 {
	color: #0d6763;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0px;
	margin-top: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.main-header-right .main-header-user .dropdown:hover .dropdown-menu {
	/* display: block; */
}
.main-header-right .main-header-user .dropdown .dropdown-menu.showdrop-menu {
	display: block;
}
.main-header-right .main-header-user .dropdown .dropdown-menu {
	padding: 0px;
	border-radius: 25px;
	box-shadow: 0px 3px 6px #00000029;
	border: none;
	display: none;
	position: absolute;
	top: 42px;
	right: 0;
	min-width: 160px;
	z-index: 1;
	background-color: #fff;
}
.main-header-right .main-header-user .dropdown .dropdown-menu ion-list {
	margin: 0px;
	padding: 0px;
	--inner-box-shadow: none;
	overflow: initial;
	display: contents;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item {
	list-style: none;
	--min-height: auto;
	--padding-start: 0;
	--padding-end: 0;
	--inner-padding-end: 0;
	--inner-padding-start: 0;
	--inner-box-shadow: none;
	overflow: initial;
	display: contents;
	--background: transparent;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item
	.dropdown-item {
	color: #0d6763;
	font-size: 12px;
	font-weight: 400;
	text-align: center;
	padding: 10px 15px;
	border-bottom: 1px solid #e6e6e6;
	width: 100%;
	display: block;
	text-decoration: none;
	cursor: pointer;
}

.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item
	.dropdown-item:hover {
	background-color: rgba(64, 79, 140, 0.15);
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item:nth-child(1)
	.dropdown-item {
	border-radius: 25px 25px 0px 0px;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item:nth-last-child(1)
	.dropdown-item {
	border-radius: 0px 0px 25px 25px;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu {
	position: relative;
	--background: transparent;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu {
	position: absolute;
	top: 0px;
	left: -130px;
	width: 150px;
	background-color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	display: block;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu
	ion-item
	.dropdown-item {
	display: flex;
	align-items: center;
	text-align: left;
	justify-content: space-between;
	padding: 3px 15px;
}

.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu
	ion-item
	.dropdown-item
	.switchbtn
	ion-toggle {
	padding: 5px 0px;
}

.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu
	ion-item
	.dropdown-item
	.dropdown-item-name {
	margin-right: 5px;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu
	ion-item:nth-child(odd)
	.dropdown-item {
	border-radius: 25px 25px 0px 0px;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu
	.drop-sub-menu
	ion-item:nth-child(even)
	.dropdown-item {
	border-radius: 0px 0px 25px 25px;
}
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item.drop-parents-menu:hover
	.drop-sub-menu {
	display: block;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	p {
	color: #0d6763;
}

.main-header-right
	.main-header-botification
	.notilist
	.notilist-item
	.notifications-drop
	.notifications-dropbox
	.notifications-dropbox-inner
	.notifications-dropbox-mid
	.notifications-dropbox-list
	a {
	cursor: pointer;
}
/* .top-header-app ion-buttons{
      display:none;
  }
  .profile-info .errormsg p{
      font-size: 14px;
      text-align: center;
      text-transform: capitalize;
      color: red;
      margin-top: 5px;
      margin-bottom: 0px;
  }
  
  ion-header ion-toolbar{
      --min-height:auto;
      --padding-start:0;
      --padding-end:0;
      --inner-padding-end:0;
  }
  .top-header-app ion-buttons{
      color:#0D6763;
      font-size: 25px;
  }
  
  .main-header-logo .logo-blue{
      display: block;
  }
  
  .main-header-logo .logo-white{
      display: none;
  }
  
  ion-select-popover ion-list{
      background: #fff !important;
  }
  
  ion-select-popover ion-list ion-radio-group ion-item{
      --color:#000;
      --background: #fff;
      --border-width: 0px;
      --border-color: #fff;
  }
  
  
  /* / modal / */
.update-password-modal .ion-page {
	background-color: #fff;
	overflow: auto;
}

.update-password-modal .form-group ion-label {
	color: #0d6763;
}

.update-password-modal .form-group ion-input {
	display: inline-block;
	background-color: #f2f2f2;
	color: #0d6763;
	font-size: 15px;
	box-shadow: 0 2px 2px #ddd;
	font-size: 14px;
	margin-bottom: 5px;
}

.dark-mode .update-password-modal .ion-page {
	background-color: #1d254c;
}

.dark-mode .adduser-popup .popup-header {
	border-bottom-color: #3f4567;
}

.dark-mode .update-password-modal .popup-header {
	border-bottom-color: #3f4567;
}

.dark-mode .update-password-modal .form-group ion-label {
	color: #fff;
}

.dark-mode .top-header-app ion-buttons {
	color: #fff;
}

/* / modal end / */

@media (max-width: 991px) {
	.searchBar {
		width: 55%;
	}
	.right {
		width: 100%;
	}
}

/* @media (min-width: 992px) {
    ion-toolbar {
      display: none;
    }
  } */

@media (max-width: 575px) {
	.main-header-logo ion-img {
		margin: 0px auto;
	}
	/* .main-header-right{
          justify-content: center;
      } */

	ion-toolbar {
		position: absolute;
		top: auto;
		left: 0;
		bottom: auto;
		--background: transparent;
		/* width: auto; */
	}
	.mainHeaderWeb .logo {
		font-size: 17px !important;
	}
	.main-header-right .main-header-botification {
		position: absolute;
		top: -40px;
		right: 0;
		width: 100%;
	}

	.main-header-right .main-header-botification .notilist {
		justify-content: flex-end;
	}

	.main-header-right
		.main-header-botification
		.notilist
		.notilist-item
		.notifications-drop
		.notifications-dropbox {
		right: 0;
	}
}

.adminRouteLink {
	display: flex;
	align-items: center;
}

.adminRouteLink h6 {
	color: #f8f872;
}
.adminRouteLink h6:hover {
	border-bottom: 2px solid #f8f872;
	cursor: pointer;
}

@media (max-width: 767px) {
	.main-header-right .main-header-user .dropdown .dropdown-toggle h4 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 12px;
	}
	.main-header-right .main-header-botification .notilist .notilist-item a {
		height: 25px;
		width: 25px;
	}

	ion-header {
		/* padding-top: 30px; */
	}
}

@media screen and (max-width: 991px) and (min-width: 576px) {
	ion-toolbar {
		position: absolute;
		top: auto;
		left: 0;

		--background: transparent;
	}
	.main-header-logo {
		margin-left: 35px;
	}
}

@media screen and (max-width: 767px) and (min-width: 576px) {
	.main-header-right
		.main-header-botification
		.notilist
		.notilist-item
		.notifications-drop
		.notifications-dropbox {
		right: 125px;
	}

	.main-header-right .main-header-user .dropdown .dropdown-menu {
		right: 0;
	}
}

/* letest */
.main-header-right
	.main-header-user
	.dropdown
	.dropdown-menu
	ion-list
	ion-item
	.sidebar-middiv {
	width: 100%;
}

.custom-checkbox {
	margin-inline-end: 15px;
	margin: 10px 15px 10px 4px;
	--border-color: #0d6763;
	--border-width: 1px;
	--background: #fff;
	--background-checked: #0d6763;
	--border-color-checked: #0d6763;
	margin-right: 7px;
}
