.signup {
    background: transparent linear-gradient(270deg, #7E2786 0%, #314A8E 100%) 0% 0% no-repeat padding-box;
    color: black;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
}
.signup .login-inner .login-logo {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
}
.signup .login-inner .login-logo ion-img{
    width: 100%;
    max-width: 200px;
    margin: 0px auto;
}
.signup .login-inner {
    max-width: 500px;
    width: 100%;
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #000 29;
}
.signup .login-inner .login-head {
    padding: 15px;
    border-bottom: 1px solid #e4e4e4;
}
.signup .login-inner .login-head h2 {
    margin-bottom: 0px;
    color: #404f8c;
    font-size: 20px;
    margin-top: 0px;
    text-transform: uppercase;
    
}
.signup .login-inner .login-body {
    padding: 15px;
}
.signup .login-inner .login-body .form-group ion-label {
    margin-bottom: 8px;
    color: #404F8C;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
}

.signup .login-inner .login-body .form-group{
    margin-bottom: 20px;
    position:relative;
}
.signup .login-inner .login-body .form-group p {
    position: absolute;
    bottom: -22px;
    margin: 0 auto;
    font-size: 12px;
    /* text-transform: capitalize; */
    color: red;
}

.signup .login-inner .login-body .form-group .form-control{
    border: 1px solid #D1D1D1;
    height: 40px;
    border-radius: 0px;
    font-size: 14px;
    display: block;
    color:black;
    width: 100%;
    --padding-end: 8px;
}

.signup .login-inner .login-body .forgot-password {
    text-align: right;
    margin-bottom: 15px;
}
.signup .login-inner .login-body .forgot-password a {
    color: #000;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}
.signup .login-inner .login-body .forgot-password a:hover {
    text-decoration: underline;
}
.signup .login-inner .login-body .button-login {
    text-align: center;
}
.signup .login-inner .login-body .button-login .gradient-btn-blue{
    min-width: 130px;
    font-size: 14px;
    border: none;
    background:#7c8181;
    outline: none;
    box-shadow: none;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    --box-shadow: none;
    text-transform: capitalize;
}

.signup .login-inner .login-head .errormsg p{
    font-size: 12px;
    text-transform: capitalize;
    color: red;
    margin-top: 5px;
    margin-bottom: 0px;
}

.signup .login-inner .login-body .haveaccount{
    text-align: center;
    padding: 10px 0px 5px;
}

.signup .login-inner .login-body .haveaccount p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.signup .login-inner .login-body .haveaccount p a {
    color: #404f8c;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
}

.signup .login-inner .login-body .button-login .gradient-btn-blue .spinner-loader{
    width: 15px;
    margin-left: 2px;
}


.signup .private-policy{
    margin-top: 10px;
}

.signup .private-policy p{
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.signup .private-policy p a{
    cursor: pointer;
    color: #404f8c;
    text-decoration: none; 
}

.signup .login-inner .login-tagline{
    padding: 15px;
}

.signup .login-inner .login-tagline h3{
    margin-bottom: 0px;
    color: #404f8c;
    font-size: 20px;
    margin-top: 0px;
    text-align: center;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.signup .login-inner .login-body .form-group input::-webkit-outer-spin-button,
.signup .login-inner .login-body .form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}





/* Firefox */
.signup .login-inner .login-body .form-group input[type=number] {
  -moz-appearance: textfield;
}



