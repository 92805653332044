.main {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: flex;
  margin-top: 20px;
}
.core-rail {
  width: 90%;
  margin: 0 auto;
}
.top-card-layout {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  /* -webkit-box-shadow: 0px -1px 1px rgba(0,0,0,0.08), 1px 0px 1px rgba(0,0,0,0.08), -1px 0px 1px rgba(0,0,0,0.08), 0px 1px 1px rgba(0,0,0,0.08); */
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08),
    -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.top-card-layout .cover-img {
  border-radius: 2px 2px 0 0;
}
.top-card-layout .cover-img {
  height: 198px;
}
.cover-img {
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-color: #f1f1f1;
}
.cover-img__image {
  background-size: cover;
  height: auto;
  width: 100%;
  position: absolute;
  max-width: none;
  max-height: none;
  min-width: none;
  min-height: none;
  /* width: 845.602px; */
  height: 231.365px;
  top: -33.3646px;
  left: 0px;
}
.top-card-layout__card {
  padding: 24px;
  position: relative;
}
.top-card-layout__entity-image {
  border: 4px solid #fff;
  margin: -100px 0 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
  position: relative;
}

.artdeco-entity-image--square-8 {
  width: 128px;
  height: 128px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: content-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
}
.artdeco-entity-image {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background-color: #f1f1f1;
}
.top-card-layout__entity-info-container {
  /* display: -webkit-box;
  display: -ms-flexbox;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}
.top-card-layout__entity-info {
  /* -webkit-box-flex: 1; */
  /* -ms-flex: 1 0;
  flex: 1 0; */
}
.top-card-layout__title {
  margin-top: 0px;
  font-size: 24px;
  line-height: normal;
  font-weight: 570;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 3px;
}
.top-card-layout__headline {
  font-size: 14px;
  margin-top: 0px;
  line-height: normal;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 0px;
}
.top-card-layout__entity-info--right-column {
  margin-left: 24px;
  max-width: 288px;
}
.section-container {
  padding: 24px 0px;
  /* border-top: 1px solid #e6e9ec; */
}
.numberOfpost {
  /* -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; */
  border-top: 1px solid #dbdbdb;
  /* border-top: 1px solid rgba(var(--b38, 219, 219, 219), 1);
  color: #8e8e8e; */
  /* display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; */
  /* font-size: 12px;
  font-weight: 600;
  -webkit-box-pack: center; */
  /* -webkit-justify-content: center;
    -ms-flex-pack: center; */
  /* justify-content: center; */
  /* letter-spacing: 1px;
  text-align: center; */
  padding: 0px 10px;
}
.postNav {
  cursor: pointer;
  display: flex;

  margin-bottom: 0px !important;
}
.postNav li {
  font-size: 14px;
  margin: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.postNav li ion-icon {
  margin-right: 3px;
  font-size: 18px;
}

.postNav li.active {
  color: #0d6763;
}

.update_list {
  list-style: none;
}

.updates .share-update-card {
  border-radius: 8px;
  width: 97%;
  margin-bottom: 12px;
}
.share-update-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08),
    1px 0px 1px rgba(0, 0, 0, 0.08), -1px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08),
    -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08);
}
.share-update-card {
  -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  position: relative;
}
.share-update-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 16px 0;
}

.share-update-card__actor-image {
  border: none;
}

.artdeco-entity-image--square-3 {
  width: 48px;
  height: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: content-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.artdeco-entity-image {
  display: inline-block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.share-update-card__actor-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 8px;
}
.share-update-card__actor-text {
  margin-bottom: 1px;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}
.card__post-date {
  margin-top: 0px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.ellipsis-menu {
  position: absolute;
  right: 0;
  top: 0;
}

.share-update-card__ellipsis-menu {
  margin-right: 4px;
}

.collapsible-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.collapsible-dropdown__button {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: inherit;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  outline-width: 2px;
  padding: 0 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition-duration: 167ms;
  transition-duration: 167ms;
  -webkit-transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, box-shadow, color;
  transition-property: background-color, box-shadow, color, -webkit-box-shadow;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
  z-index: 0;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  padding-left: 0;
}

.ellipsis-menu__trigger {
  padding: 6px 8px 12px;
  border-radius: 50%;
}

.share-images {
  width: 100%;
  height: 272px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
}

.share-images__image-container:first-child:nth-last-child(1) {
  width: 100%;
  height: 100%;
}
.share-images__image-container {
  position: relative;
  background: #f3f6f8;
}
.share-images__image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.social-action-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e6e9ec;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 8px 16px 0;
}
.social-action-bar__button {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  font-family: inherit;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  outline-width: 2px;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition-duration: 167ms;
  transition-duration: 167ms;
  -webkit-transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, color, -webkit-box-shadow;
  transition-property: background-color, box-shadow, color;
  transition-property: background-color, box-shadow, color, -webkit-box-shadow;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
  z-index: 0;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}
.social-action-bar .social-action-bar__icon,
.social-action-bar__button .social-action-bar .social-action-bar__icon {
  position: static;
  display: inline-block;
  width: 24px;
  height: 24px;
  /* margin: 8px 0 0; */
  padding: 0;
  vertical-align: middle;
}

/* .Details{
    padding: 0px 24px;
} */

.Details .share-details-card {
  padding: 0px 24px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 12px;
}
.Details {
  padding: 0px;
  /* margin-right: 10px; */
}
.allposts {
  /* margin: 0px 0px 0px 60px; */
  margin: 0px;
  padding: 0px !important;
}
.share-details-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08),
    1px 0px 1px rgba(0, 0, 0, 0.08), -1px 0px 1px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.08), 1px 0px 1px rgba(0, 0, 0, 0.08),
    -1px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08);
  margin-bottom: 15px;
}
.share-details-card {
  -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
  background: #fff;
  position: relative;
}
.share-details-card ul li {
  border-bottom: 1px solid #e6e9ec;
}
.share-details-card ul li h5 {
  font-weight: 600;
}
.share-details-card ul li h6 {
  color: #000000;
}

/* .modal-wrapper .close {
  height: 2.5rem;
  width: 2.5rem;
  min-width: auto;
  position: absolute;
  z-index: 1;
  top: 1.2rem;
  right: 1.2rem;
} */

/* .modal-wrapper .modal-header {
  display: block;
  padding: 1.6rem 2.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-family: var(--artdeco-typography-sans);
  font-size: 1.6rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-style: normal;
} */

/* .modal-wrapper .modal-header h2 {
  font-size: 1.7rem;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding-right: 1.6rem;
} */
/* .share-box__text-editor {
  font-size: 1.4rem;
  line-height: 1.33333;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  margin: 12px 24px;
} */
/* .ql-editor {
  border: none;
  line-height: 1.42;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
} */
.share-details-card__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 16px 0;
}
.feed {
  display: flex;
}

.explore_and_results {
  display: flex;
}

@media (max-width: 767px) {
  .feed {
    display: block;
  }
  .allposts {
    margin: 0px;
    padding: 0px !important;
  }
  .updates .share-update-card {
    width: 100%;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  .allposts {
    /* margin: 0px 0px 0px 45px; */
    margin: 0px 0px 0px 0px;
    padding: 0px !important;
  }
}

.file-custom {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;

  appearance: initial;
  background-color: initial;
  cursor: default;
  align-items: baseline;
  color: inherit;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: start !important;
  padding: initial;
  border: initial;

  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 33px;
  cursor: pointer;
  left: 0;
  right: 10px;
}
.inputChanges {
  border-color: transparent;
}
.inputChanges:focus {
  width: 100%;
  outline: none;
  border-bottom: 1px solid blue;
}
.reusableli {
  width: 100%;
}

.entry_list_items {
  /* display: block; */
  display: grid;

  /* max-width: 50%; */
  /* padding: 15px */
}
.entity_list__image {
  margin: 12px 8px 12px 16px;
}
.entity_list__image img {
  background-color: black;
  border-radius: 29px;
  width: 65px;
  height: 65px;
}
.entity_list_content {
  flex: 1;
  min-width: 0;
  text-align: center;
}
.entity_list_content_info {
  margin-bottom: 4px !important;
}
.connectionsAll {
  display: block;
  margin: 0px !important;
}

.entity_list_actions {
  /* display: flex; */
  display: inline-block;
  justify-content: flex-end;
  align-items: middle;
  margin-left: auto;
  padding-top: 16px;
  padding-right: 16px;
  /* min-width: 122px; */
  margin: 0px 2%;
  /* width: 50%; */
  float: right;

  /* margin: 10px;
    display: block;
    width: 100%; */
}
.entity_list_actions ion-button {
  --background: #0d6763;
}

.editimg-box {
  /* border: 1px solid #000; */
  /* border-radius: 4px; */
  padding: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
/* 


.editimg-box .editimg-inner {
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.cover-img__image {
  width: 100%;
}

 */
.editimg-box .editimg-box-btn {
  position: absolute;
  right: 10px;
  width: 34px;
  border: 1px solid #0d6763;
  border-radius: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d6763;
  cursor: pointer;
  opacity: 0.9;
}

.editimg-box .editimg-box-btn ion-icon {
  font-size: 16px;
  color: #fff;
}

.editimg-box .editimg-box-btn-profile {
  position: absolute;
  top: 25px;
  left: 5px;
  width: 25px;
  overflow: hidden;
  float: left;
  border: 1px solid;
  border-radius: 90%;
}

.editimg-profilebox {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  border: 1px solid #e4e4e4;
  padding: 5px;
  border-radius: 4px;
  margin-top: -85px;
}

.editimg-profilebox-inner {
  overflow: hidden;
  width: 115px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editimg-profilebox-inner img {
  width: 100%;
  border-radius: 4px;
}

.editimg-profilebox-inner a {
  position: absolute;
  top: 43px;
  left: 0;
  right: -111px;
  background-color: #0d6763;
  padding: 5px 8px;
  border-radius: 50px;
  color: #fff;
  opacity: 0.9;
  margin: 0 auto;
  text-align: center;
  width: 34px;
  height: 34px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editimg-profilebox-inner a ion-icon {
  color: #fff;
  color: #fff;
}

.caption {
  padding: 0px 15px 5px;
}
.alert-wrapper.sc-ion-alert-md {
  background-color: #ffffff;
}

/* modal */
.addimg-modal .imageadd-inner {
  margin-bottom: 20px;
}
.addimg-modal .imageadd-inner ion-img {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
}

.text-editor-modal .text-editor-img {
  margin-bottom: 20px;
}

.text-editor-modal .text-editor-img ion-img {
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
}
.ion-green-button {
  width: 100%;
}

/* media css */
@media (max-width: 991px) {
  .main {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {
  .top-card-layout__entity-info-container {
    flex-flow: column;
    align-items: end;
  }

  .top-card-layout__entity-info {
    margin-bottom: 10px;
  }
  .connectionsAll {
    display: flex;
  }
  .entity_list_actions {
    width: 100%;
    /* float:right; */
  }
  .ion-green-button {
    width: 100%;
  }

  /* .entry_list_items {
    display: flex;
    max-width: 100%;
    padding: 12px 0px;
  } */
}

@media (max-width: 762px) {
  ion-modal {
    margin-top: 60px;
  }
}
