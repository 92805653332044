ion-content{
    --font-family:Montserrat, sans-serif !important;
   --ion-font-family:Montserrat, sans-serif !important;
   font-family:Montserrat, sans-serif !important;
   --font-size: 18px;font-size: 18px;
   letter-spacing: 0.4px;
   --letter-spacing: 0.4px;
   --color: #353535;
      -webkit-font-smoothing: antialiased !important;
      --font-weight: 500;
  }

  ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
}
ol.gradient-list > li {
    background: white;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 0.5rem .5rem .5rem 2.5rem;
    position: relative;
}
ol.gradient-list > li::before, ol.gradient-list > li::after {
    background: #0D6763;
    border-radius: 1rem 1rem 0 1rem;
    content: '';
    height: 2.3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 2.3rem;
}
ol.gradient-list > li::before {
    align-items: flex-end;
    /* content: counter(gradient-counter); */
    color: #1d1f20;
    display: flex;
    font: 900 1.5em/1 'Montserrat';
    justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
}

ol.gradient-list > li:nth-child(10n+1):before {
   
    background-repeat:no-repeat ;
    background-position: center;   /* background:linear-gradient(135deg, rgb(7 71 166) 0%, rgb(0 0 0) 100%); */
}
ol.gradient-list > li:nth-child(10n+2):before {
    background: #0D6763;
}
ol.gradient-list > li:nth-child(10n+3):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+4):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+5):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+6):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+7):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+8):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+9):before {
    background: #0D6763
}
ol.gradient-list > li:nth-child(10n+10):before {
    background: #0D6763
}
ol.gradient-list > li + li {
    margin-top: 2rem;
}
  