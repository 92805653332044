/* Welcome board */
.viewWelcomeBoard {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border-left: 1px solid #cccccc;
    height: 100%;
    padding-top: 40px;
    opacity: 0.4;
    height: 100%;
}

.textTitleWelcome {
    font-size: 40px;
    font-weight: bold;
    color: #203152;
}

.textDesciptionWelcome {
    font-size: 20px;
    color: #203152;
    margin-bottom: 200px;
}

.avatarWelcome {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: cover;
    /* background-image: url(../../images/nopic.jpg); */
  
}