.social-action-bar .social-action-bar__icon {
	height: 18px !important;
	margin-right: 10px;
	width: 18px !important;
}
.social-action-bar {
	justify-content: space-evenly;
	margin: 12px 0 0 !important;
	padding: 8px 25px 8px !important;
	height: auto !important;
	border-top: 1px solid #323232 !important;
}
.line {
	position: relative;
}
.social-action-bar__button {
	color: #262626 !important;
}
.line:before {
	content: "";
	position: absolute;
	border-right: 1.5px solid #323232;
	right: 0;
	width: 1px;
	height: 22px;
	top: 0;
	bottom: 0;
	margin: auto;
}
.card__post-date {
	margin: 0 !important;
}
.share-update-card__actor-text {
	padding-bottom: 2px !important;
	margin: 0 !important;
}
.imgSocial {
	padding: 0 15px;
}
.Details1 {
	background-color: #ffffff;
	border-radius: 8px;
	padding: 0 15px !important;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15),
		0px 0px 2px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15),
		0px 0px 2px rgba(0, 0, 0, 0.15);
}
.Details1 h3 {
	text-align: center;
	line-height: 1.42857;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.9);
	font-size: 18px;
	margin-bottom: 20px;
}

.newDetails1 h4 {
	margin-bottom: 10px !important;
}
.share-update-card__actor-text {
	font-size: 18px !important;
}
.Details .card__post-date {
	font-size: 14px !important;
}
.Details .share-update-card__actor-info {
	text-align: left;
	align-items: flex-start;
}
.Details hr {
	border-bottom: 1px solid #c6c6c6;
}
.Details .share-update-card__header {
	padding-top: 0;
}

.postCommentBoxSection {
	display: flex;
	align-items: center;
	padding: 0px 0px 12px;
}
.postCommentBoxSection input:focus {
	background-color: rgb(137, 184, 182, 0.1);
}
.postCommentBox {
	flex: 1;
	padding: 2px 10px;
}

.commentBoxStyle {
	display: flex;
	padding: 5px;
	flex-direction: column;
	/* background-color: #f2f1f1; */
}

.commentBoxComment {
	display: flex;
	flex-direction: column;
	color: black;
	font-size: 13px;
	width: 100%;
	background-color: #f2f1f1;
	padding: 10px;
	border-radius: 10px;
}

.breakInParts {
	display: flex;
	align-items: flex-start;
}

.commentBoxProfileImage {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 2px solid black;
	margin-right: 10px;
	margin-top: 7px;
}

.social-action-bar__button:hover {
	background-color: #f2f1f1;
}

.social-action-bar__button:hover .social-action-bar__icon,
.social-action-bar__button:hover span {
	transform: scale(1.05);
	color: #296763;
}

@media (max-width: 568px) {
	.social-action-bar__button {
		font-size: 12px !important;
	}
	.social-action-bar {
		padding: 0 !important;
	}
	.social-action-bar .social-action-bar__icon {
		margin-right: 2px;
		width: 14px !important;
	}
	.core-rail {
		width: 100%;
	}
	.Details h3.share-update-card__actor-text {
		font-size: 14px !important;
	}
	.Details .card__post-date {
		font-size: 12px !important;
	}
}

@media screen and (max-width: 460px) {
	.comment-button-green-mobile {
		width: 100%;
		letter-spacing: 3px;
	}
	.postCommentBoxSection {
		display: flex;
		flex-direction: column;
	}
	.postCommentBoxSection input {
		width: 100%;
		margin-bottom: 5px;
	}
}
