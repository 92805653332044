ion-content {
  /* --background: #efefef; */
  --background: #e9f4f3;
}

/* search bar */
ion-searchbar {
  --background: #fff !important;
  --color: #000 !important;
  --padding: 0px !important;
}
ion-radio {
  float: right;
}
ion-select-popover {
  background: #fff !important;
}
/* Theme Buttons */
.button-green {
  font-size: 14px;
  border: none;
  --background: linear-gradient(#0d6763, #30a7a1);
  outline: none;
  box-shadow: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  --box-shadow: none;
  text-transform: capitalize;
  line-height: normal;
  --background-activated: linear-gradient(#0d6763, #30a7a1);
  --padding-top: 3px;
  font-weight: 400;
}

.button-grey {
  font-size: 14px;
  border: none;
  --background: linear-gradient(#a0a0a0, #6f6f6f);
  outline: none;
  box-shadow: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  --box-shadow: none;
  text-transform: capitalize;
  line-height: normal;
  --background-activated: linear-gradient(#a0a0a0, #6f6f6f);
  --padding-top: 3px;
  font-weight: 400;
}

/* theme modal */
.modal-wrapper {
  --background: #ffffff;
  --border-radius: 4px;
}

.modal-wrapper .modal-header h2 {
  font-family: var(--artdeco-typography-sans);
  color: rgba(0, 0, 0, 0.9);
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.modal-wrapper .ion-page .modal-header {
  padding: 15px;
}

.modal-wrapper .ion-page .modal-header .close {
  color: #000;
  cursor: pointer;
}

.modal-wrapper .ion-page .modal-body {
  padding: 15px;
  min-height: 350px;
}

.modal-wrapper .ion-page .modal-footer {
  padding: 15px;
  justify-content: center;
}

.modal-wrapper .ion-page .modal-footer .modal-footer-btns {
  display: flex;
}

.modal-wrapper .modal-input-group {
  margin-bottom: 15px;
}

.modal-wrapper .modal-input-group .modal-input {
  background-color: #fff;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #fff;
  outline: none;
  box-shadow: none;
  width: 100%;
}

/* margin css */
.mb-10 {
  margin-bottom: 10px;
}

ion-content {
  pointer-events: auto;
}
ion-toolbar.large-title-fix {
  padding-top: env(safe-area-inset-top);
}
@media (min-width: 992px) {
  .container {
    max-width: 1024px !important;
  }
}
