.login {
    /* background: transparent linear-gradient(270deg, #7E2786 0%, #314A8E 100%) 0% 0% no-repeat padding-box; */
    background-image: url("../../images/GM-BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px;
}
.login .login-inner .login-logo {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 10px;
}
.login .login-inner .login-logo ion-img  {
    width: 100%;
    max-width: 200px;
    margin: 0px auto;
}
.login .login-inner {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    background-color: #fff;
    box-shadow: 0px 5px 10px #d0cccc;
    border-radius: 15px;
}
.login .login-inner .login-head {
    padding: 15px;
    border-bottom: 1px solid #e4e4e4;
}
.login .login-inner .login-head h2 {
    margin-bottom: 0px;
    color: #404f8c;
    font-size: 20px;
    margin-top: 0px;
    text-transform: uppercase;
}
.login .login-inner .login-body {
    padding: 15px;
}
.login .login-inner .login-body .form-group ion-label {
    margin-bottom: 8px;
    color: #404F8C;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    display: block;
}

.login .login-inner .login-body .form-group{
    margin-bottom: 25px;
    position: relative;
}

.login .login-inner .login-body .form-group p {
    position: absolute;
    bottom: -18px;
    margin: 0 auto;
    font-size: 12px;
    /* text-transform: capitalize; */
    color: red;
}

.login .login-inner .login-body .form-group .form-control{
    border: 1px solid #D1D1D1;
    height: 40px;
    border-radius: 0px;
    font-size: 13px;
    display: block;
    width: 100%;
    --padding-start:10px;
    --padding-end: 10px;
}

.login .login-inner .login-body .forgot-password {
    /* margin-bottom: 15px; */
    overflow:hidden;
    min-height: 25px;
}
.login .login-inner .login-body .forgot-password a {
    color: #404f8c;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    vertical-align: text-top;
    text-decoration: none;
}
.login .login-inner .login-body .forgot-password .forgot-text{
    float: right;
}
.login .login-inner .login-body .forgot-password a:hover {
    text-decoration: none;
}
.login .login-inner .login-body .button-login {
    text-align: center;
}
.login .login-inner .login-body .button-login .gradient-btn-blue{
    min-width: 100px;
    font-size: 14px;
    border: none;
    background: #0D6763;
    outline: none;
    box-shadow: none;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    --box-shadow: none;
    text-transform: capitalize;
}

.login .login-inner .login-body .button-login .gradient-btn-blue .spinner-loader{
    width: 15px;
    margin-left: 2px;
}


.login .errormsg p{
    font-size: 12px;
    text-align: center;
    /* text-transform: capitalize; */
    color: red;
    margin-top: 5px;
    margin-bottom: 0px;
}
.login .successmsg p{
    font-size: 13px;
    text-align: center;
    text-transform: capitalize;
    color: green;
    margin-top: 5px;
    margin-bottom: 0px;
}
.main-header-user{
width:160px;
}
.menulist ion-menu-toggle {
    cursor: pointer;
}

.login .private-policy{
    margin-top: 10px;
}

.login .private-policy p{
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    text-align: center;
    line-height: 16px;
}

.login .private-policy p a{
    cursor: pointer;
    color: #404f8c;
    text-decoration: none; 
}

@media (max-width: 767px) {
.login .login-inner .login-body .forgot-password .forgot-text {
        float: none;
        /* margin-top:10px; */
        display: block;
}
.main-header-user {
    width: auto;
}
}

.login .login-inner .login-body .haveaccount{
    text-align: center;
    padding: 10px 0px 5px;
}

.login .login-inner .login-body .haveaccount p{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.login .login-inner .login-body .haveaccount p a{
    color: #404f8c;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
}
.login .showpassword-input a {
    position: absolute;
    top: 40px;
    right: 8px;
    text-decoration: none;
    cursor: pointer;
    z-index: 999;
}


/* google btn */
/* 
.button-login #loginButton {
    min-width: 100px;
    font-size: 14px;
    border: none;
    background: transparent linear-gradient(270deg, #7E2786 0%, #314A8E 100%) 0% 0% no-repeat padding-box;
    outline: none;
    box-shadow: none;
    border-radius: 25px;
    color: #fff;
    cursor: pointer;
    --box-shadow: none;
    text-transform: capitalize;
    position: relative;
    padding: 0px 10px;
}

.button-login #loginButton .abcRioButton:hover{
    box-shadow: none;
    outline: none;
}

.button-login #loginButton .abcRioButtonLightBlue {
    background-color: transparent;
    color: #fff;
    width: auto !important;
}

.button-login #loginButton .abcRioButtonLightBlue .abcRioButtonContentWrapper {
    display: flex;
    justify-content: center;
}

.button-login #loginButton .abcRioButtonLightBlue .abcRioButtonContentWrapper .abcRioButtonIcon{
    padding: 15px 0px !important;
}

.button-login #loginButton .abcRioButtonLightBlue .abcRioButtonContentWrapper .abcRioButtonContents{
    font-size: 14px !important;
    display: none;
} */

.button-login .login-social-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-btn-google{
    margin: 0px 3px;
}

.icon-btn-google ion-button{
    --padding-start:0px;
    --padding-end:0px;
    --box-shadow:none;
}

.icon-btn-google ion-button .abcRioButtonLightBlue {
    background-color: transparent;
    width: auto !important;
    height: auto !important;
    box-shadow: none;
}


.icon-btn-google ion-button .abcRioButtonLightBlue  .abcRioButtonContents{
    display: none;
}

.abcRioButtonContentWrapper .abcRioButtonIcon{
    padding: 0px !important;
}

.icon-btn-facebook ion-button{
    --padding-start:0px;
    --padding-end:0px;
    --box-shadow:none;
}

.icon-btn-facebook ion-button ion-icon{
    color: #3b5998;
    font-size: 23px;
}

.icon-btn-facebook{
    margin: 0px 3px;
}

.button-login .login-social-heading p{
    margin-bottom: 0px;
    color: #9a9a9a;
    font-size: 14px;
    font-weight: 500;
}

.check-forgot{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-checkbox-inline{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-top: -15px;
}

.custom-checkbox-inline ion-label{
    margin: 0px;
    padding: 0px;
    color: #000;
    font-size: 14px;
}