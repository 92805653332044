.change-password-popup {
  margin-top: 30px;
}
/* menu css */
.dashion-menu {
  --width: 250px;
  border-right: 0px;
}
.dashion-menu-app {
  --width: 250px;
  border-right: 0px;
}

ion-menu {
  --max-width : 180px;
  --width:100px !important;  
--side-max-width : 0% !important;
--side-min-width: 175px !important
}
ion-menu ion-content {
  /* --background: #efefef; */
  --background: #E9F4F3;
  /* linear-gradient(270deg, #7e2786 0%, #314a8e 100%) 0% 0% */
  /* no-repeat padding-box; */
}

ion-menu ion-content .menulist {
  background: transparent;
}

ion-menu ion-content .menulist ion-item {
  --background: transparent;
  border-left: 3px solid transparent;
  --min-height: auto;
  cursor: pointer;
}

ion-menu ion-content .menulist ion-item ion-label {
  color: green !important;
  font-size: 14px !important;
  margin: 0px;
  padding: 14px 0px;
}

ion-menu ion-content .menulist ion-item ion-icon {
  color: green;
  width: 16px;
  margin: 0px 10px 0px 0px;
}

ion-menu ion-content .menulist ion-item .sidebar-middiv {
  /* display: flex; */
  align-items: center;
  width: 110%;
}
ion-menu ion-content .menulist ion-item .sidebar-middiv a{
  display: block;
}

ion-menu ion-content .menulist ion-item .sidebar-middiv:focus {
  outline: none;
}
ion-menu ion-content .menulist ion-item .sidebar-middiv ion-label {
  border-radius: 14px;
  padding: 15px 13px;
  margin: 5px 0px;
}
ion-menu ion-content .menulist ion-item .sidebar-middiv a:hover {
  background: aliceblue ;
  border-radius: 14px;
  --background-hover: none
  /* padding: 15px 13px;
  margin: 5px 0px; */
}
ion-menu ion-content .menulist ion-item:hover {
  
  --background-hover: none !important
  
}


ion-menu ion-content .menulist ion-item ion-label:focus {
  outline: none;
  
}

ion-menu ion-content .menulist ion-item.selected {
  color: green;
  border-left-color: #fff;
}

ion-menu ion-content .menulist ion-item.selected ion-label {
  color: #fff !important;

}

ion-menu ion-content .menulist ion-item.selected ion-icon {
  color: #fff;
}

.sidebar-head {
  color: white;
  background: #0d6763;
  padding: 13px 10px 12px 10px;
  text-align: center;
}
.sidebar-head .sidebar-user .sidebar-user-img {
  border: 1px solid green;
  border-radius: 10px;
  padding: 5px;
  width: 70px;
  margin: 0px auto 10px;
}
.sidebar-head .sidebar-user .sidebar-user-img .sidebar-user-img-inner {
  background-color: green;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-head .sidebar-user .sidebar-user-img .sidebar-user-img-inner img {
  width: 100%;
}
.sidebar-head .sidebar-user .sidebar-username h2 {
  color: #ffffff;
  font-size: 26px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.menulist {
  position: relative;
  height: 73vh;
}
.logOutMenu {
  position: absolute;
  bottom: 0;
}

.top-header ion-toolbar {
  width: 40px;
}

ion-menu ion-content .menulist ion-item ion-img {
  width: 20px;
  margin: 0px 7px 0px 0px;
  opacity: 0.7;
}

ion-menu ion-content .menulist ion-item.selected ion-img {
  opacity: 1;
}


.sidebar-middiv ion-label {
font-weight: bold !important;
/* sidebar-middiv */
}

.sidebar-middiv a:hover {
  text-decoration: none;
}
ion-icon {
  font-weight: bold;
}


@media (min-width: 992px) {
  .dashion-menu {
    width: 250px;
    display: none;
  }
}
@media (min-width: 992px) {
  .dashion-menu-app {
    width: 250px;
    /* display: none; */
  }
  
}

@media (max-width: 992px) {
    ion-menu {
    --max-width : 250px !important;
    --width:230px !important;  
  --side-max-width : 0% !important;
  --side-min-width: 175px !important
  }
  ion-menu ion-content .menulist ion-item ion-label {
    padding: 15px 0px;
  }
}

/* @media (max-width: 767px) {
 

} */




