/* Chat board */

.viewChatBoard {
    background-color: #E9F4F3;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    position: relative;
    
    /* background-image: url("https://i.pinimg.com/474x/2e/2e/25/2e2e25c53d858f4af66151304fdb68b7.jpg"); */
 
    backface-visibility: hidden;
    border-left: 1px solid #ededed;
    /* border-right: 5px solid #DFA375; */
    max-height: 100vh;
    min-height: 100vh;
    /* margin-right: 20px; */
    border-radius: 10px;
    /* box-shadow: 0 5px 5px #808888; */
   
  
}
.viewAvatarItem{
    margin-left: 20px;
    /* background-image: url('../../images/nopic.jpg'); */
}

.headerChatBoard {
    border-bottom: 1px solid #e8e8e8;
    padding: 5px;
    display: flex;
    
    
    background-color: #ededed;
    
}
.aboutme{
    padding-top: 20px;
    margin-left: 80px;
    
    align-items: center;
    display: flex;
    position: absolute;
}
.textHeaderChatBoard {
    font-weight: bold;
    color: #203152;
    margin-left: 10px;
    
}

.viewListContentChat {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 20px; */
}

.viewListContentChat::-webkit-scrollbar-track {
    padding: 2px 0;
}

.viewListContentChat::-webkit-scrollbar {
    width: 15px;
}

.viewListContentChat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e8e8e8;
}


.viewBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height:70px;
   
    background-color: #f0f0f0;
}

.icOpenGallery {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.viewInputGallery {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: 10px;
    width: 30px;
}

.icOpenSticker {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.icSend {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 30px;
}

.viewInput {
    flex: 1;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0px;
    height: 50px;
    margin-right: 10px;

}

.viewInput:focus {
    outline: 0;
}

input::placeholder {
    color: rgb(199, 199, 199);
}

/* View item message */

.viewItemRight {
    width: 300px;
    height: auto;
   
    background-color: #dcf8c6;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 5px 5px #808888;
}

.viewItemRight2 {
    width: 300px;
    height: auto;
    background-color: #e1f3fb;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    color: #203152;
    text-align: left;
    box-shadow: 0 5px 5px #808888;
}

.viewItemRight3 {
    width: 300px;
    height: auto;
    align-self: flex-end;
    margin-right: 20px;
    margin-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    text-align: left;
   
}

.viewWrapItemLeft {
    width: 300px;
    text-align: left;
    align-self: flex-start;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -15px;
   
}

.viewWrapItemLeft2 {
    width: 300px;
    align-self: flex-start;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: -15px;
    
}

.viewWrapItemLeft3 {
    flex-direction: row;
    display: flex;
    width: 340px;
    margin-bottom: -15px;
 
   
}

.viewItemLeft {
    width: 300px;
    height: auto;
    background-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #303030;
    box-shadow: 0 5px 5px #808888;
}

.viewItemLeft2 {
    width: 300px;
    height: auto;
    background-color: #203152;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
    box-shadow: 0 5px 5px #808888;
}

.viewItemLeft3 {
    width: 300px;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    color: white;
    
}
.time{
    display:flex;
    /* align-items: center; */
    text-align: center;
    /* margin-left: 400px; */
    /* font: italic small-caps bold 15px/30px Georgia, serif; */
    width: 110px;
    background-color: #e1f3fb;
    /* padding-top: 5px; */
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;

}
.timesetup{
    align-items: center;
}

.peerAvatarLeft {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    object-fit: cover;
    align-self: flex-end;
    
}

.viewPaddingLeft {
    width: 40px;
}

/* Item text */

.textContentItem {
    font-size: 16px;
    word-break: break-all;
}

.viewListContentChat div:last-child {
    margin-bottom: 10px;
}

/* Item image */

.imgItemRight {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
}

.imgItemLeft {
    object-fit: cover;
    width: 300px;
    height: 300px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
}

.textTimeLeft {
    color: #808888;
    font-size: 12px;
    font-style: italic;
    margin-left: 50px;
}

/* Stickers */

.viewStickers {
    display: block;
    border-top: 1px solid #e8e8e8;
    height: 100px;
    align-items: center;
    justify-content: space-around;
    overflow-x: scroll;
}
.viewStickers::-webkit-scrollbar-track {
    padding: 2px 0;
}

.viewStickers::-webkit-scrollbar {
    width: 15px;
}

.viewStickers::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e8e8e8;
}

.imgSticker {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

/* Say hi */

.viewWrapSayHi {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.textSayHi {
    color: #808888;
    font-size: 14px;
}


.imgWaveHand {
    width: 40px;
    height: 40px;
    margin-left: 10px;
}

.backk{
    color: white;
    width: 110%;
    --background:#0d6763d6;
}

@media only screen and (max-width: 800px) {
    .backbutton{
        display: block;
        z-index: 100;
    display: block;
    position: fixed;
    margin-top: -63px;
    width: 100%;
    margin-left: -43px;
    }
  }

 
  @media (min-width: 767px) {
  .backbutton{
    display: none;
}
  }
  @media (max-width: 460px) {
    .viewItemRight {
        width: 260px;}
        .viewItemLeft {
            width: 260px;}
            .icSend {
               
                margin-left: 0px;
                margin-right: 0px;
            }
            .viewInput {
                padding-right: 10px
            }
            /* .allchats{
                padding-bottom:100px ;
                --padding-bottom:100px;
            } */
            .viewListContentChat{
                padding-bottom:80px ;
            }
            .inputFooter{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
            
            }
            /* .viewListContentChat{
                padding-bottom:80px ;
            } */

    }