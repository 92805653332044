:host {
  font-family: Montserrat, sans-serif !important;
  --ion-font-family: Montserrat, sans-serif !important;
}
.headerWeb-md::after {
  display: none;
}
#header {
  background: #0d6763;
} /*#314A8E */
.mainHeaderWeb {
  --background: #0d6763;
  /* --background: transparent linear-gradient(270deg, #7E2786 0%, #314A8E 100%) 0% 0% no-repeat padding-box; */
  font-size: 14px;
  /* background-color: rgba(0, 20, 42, 1) !important; */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* padding-bottom: 10px !important; */
  padding-top: 0px !important;
  /* --background:#000000 !important; */
}
.mainHeaderWeb a {
  float: left;
  display: block;
  /* color: #f2f2f2; */
  text-align: center;
  padding: 5px 0px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}

.mainHeaderWeb a:hover {
  border-bottom: 3px solid rgb(7, 255, 63);
}
.mainHeaderWeb a {
  float: left;
  display: block;
  /* color: #f2f2f2; */
  text-align: center;
  padding: 5px 0px;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}

.mainHeaderWeb a:hover {
  border-bottom: 3px solid yellow;
}

.mainHeaderWeb .active {
  border-bottom: 3px solid yellow;
}
body {
  position: relative;
}
body {
  position: relative;
}
.title {
  --color: #ffffff;
}
.mainHeaderWeb .toolbar-container {
  display: flex;
}
.mainHeaderWeb a:hover {
  text-decoration: none;
}
.mainHeaderWeb .logo {
  padding-top: 5px;
  font-size: 20px;
}
.mainHeaderWeb .container {
  margin-top: 6px !important;
  overflow: hidden;
  margin-bottom: 1px;
}
.headerWeb {
  /* position: absolute; */
  top: 0;
  z-index: 99;
  width: 100%;
  /* padding: 10px 0; */
  padding: 4 * px 0;
  /* background: transparent; */
}
.headerr {
  display: flex;
}
.mainNav {
  margin-right: 15px;
  display: flex;
  margin-bottom: 0px !important;
  list-style-type: none;
}
.mainNav li a {
  min-width: 70px;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  padding: 8px 10px;
}
.mainNav li button {
  margin-top: 4px;
}
.mainNav li {
  display: inline-block;
}
.titleList {
  display: flex;
}
.headerWeb .mobile-nav-toggle {
  display: none;
}
.power {
  margin: 0;
  line-height: normal;
  letter-spacing: normal;
}
.logoTitle {
  display: inline-block;
}
.logoImg {
  float: left;
  max-width: 45px;
}
@media (max-width: 575px) {
  .logoImg {
    max-width: 35px;
  }
}
@media (max-width: 991px) {
  .mobile-nav-toggle {
    float: right;
    display: inline-block;
    background: transparent;
  }
  .kalyug {
    width: 15% !important;
  }
  .mainHeaderWeb .container {
    display: flex;
  }
}
@media (min-width: 992px) {
  .kalyug {
    display: none;
  }
}
