.editor-page{
    padding: 15px;
    background-color: #f5f7ff;
}

.editor-page .editor-page-logo{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.editor-page .editor-page-logo img{
    width: 100%;
    max-width: 300px;
}

.editor-page .editor-page-title{
    margin: 0px 0px 25px;
    font-size: 40px;
}

.editor-page h1{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page h2{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page h3{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page h4{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page h5{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page h6{
    color: #404f8c;
    margin-top: 20px;
}

.editor-page p{
    color: #000;
    font-size: 16px;
}